import { Link, Text } from "@chakra-ui/react"
import { Link as RemixLink } from "@remix-run/react"
import {
  TALENT_SIGNAL_STATUS,
  TALENT_SIGNAL_TYPE,
} from "~/consts/talentSignals"
import { GetPeopleByID } from "~/utils/db/getPeopleByID"

interface Props {
  talentSignal: GetPeopleByID
}

export const TalentSignalTitle = ({
  talentSignal,
}: Props): JSX.Element | null => {
  // const location = useLocation()
  // const isEmbed = location.pathname.includes("/embed")

  if (!("signal_status" in talentSignal)) return null

  const {
    signal_status,
    current_position_title,
    signal_type,
    past_position_company_website,
    current_position_company_website,
    past_position_company_name,
    current_position_company_name,
    past_position_specter_company_id,
    current_position_specter_company_id,
  } = talentSignal

  const PastCompanyLink = () => {
    return (
      <Link
        color="brand.500"
        onClick={(e) => e.stopPropagation()}
        position="relative"
        zIndex={1}
        {...(past_position_specter_company_id
          ? {
              as: RemixLink,
              to: `/signals/company/feed/${past_position_specter_company_id}/f`,
              // target: isEmbed ? "_blank" : undefined,
              target: "_blank",
            }
          : {
              href: past_position_company_website ?? undefined,
              target: "_blank",
              to: "",
            })}
      >
        {past_position_company_name}
      </Link>
    )
  }

  const NewCompanyLink = () => {
    return (
      <Link
        color="brand.500"
        {...(current_position_specter_company_id
          ? {
              as: RemixLink,
              to: `/signals/company/feed/${current_position_specter_company_id}/f`,
              // target: isEmbed ? "_blank" : undefined,
              target: "_blank",
            }
          : {
              href: current_position_company_website ?? undefined,
              target: "_blank",
              to: "",
            })}
        position="relative"
        zIndex={1}
        {...(!current_position_company_website && {
          cursor: "text",
          textDecoration: "none !important",
        })}
        onClick={(e) => e.stopPropagation()}
      >
        {current_position_company_name}
      </Link>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.LIVE &&
    (signal_type === TALENT_SIGNAL_TYPE.NEW_COMPANY ||
      signal_type === TALENT_SIGNAL_TYPE.NEW_FUND)
  ) {
    return (
      <>
        Left <PastCompanyLink /> and started a {signal_type}: <NewCompanyLink />{" "}
        as "{current_position_title}"
      </>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.STEALTH &&
    (signal_type === TALENT_SIGNAL_TYPE.NEW_COMPANY ||
      signal_type === TALENT_SIGNAL_TYPE.NEW_FUND)
  ) {
    return (
      <>
        Left <PastCompanyLink /> and started a {signal_type} in{" "}
        <Text as="span" color="brand.500">
          Stealth
        </Text>
      </>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.OUT_OF_STEALTH &&
    (signal_type === TALENT_SIGNAL_TYPE.NEW_COMPANY ||
      signal_type === TALENT_SIGNAL_TYPE.NEW_FUND)
  ) {
    return (
      <>
        Comes Out of Stealth and announces a {signal_type}: <NewCompanyLink />{" "}
        as "{current_position_title}"
      </>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.LIVE &&
    signal_type === TALENT_SIGNAL_TYPE.NEW_ROLE
  ) {
    return (
      <>
        Left <PastCompanyLink /> and started a New Role at <NewCompanyLink /> as
        "{current_position_title}"
      </>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.STEALTH &&
    signal_type === TALENT_SIGNAL_TYPE.NEW_ROLE
  ) {
    return (
      <>
        Left <PastCompanyLink /> and started a New Role in{" "}
        <Text as="span" color="brand.500">
          Stealth
        </Text>
      </>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.OUT_OF_STEALTH &&
    signal_type === TALENT_SIGNAL_TYPE.NEW_ROLE
  ) {
    return (
      <>
        Comes Out of Stealth and announces a New Role at <NewCompanyLink /> as "
        {current_position_title}"
      </>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.HR &&
    signal_type === TALENT_SIGNAL_TYPE.SEARCHING
  ) {
    return (
      <>
        Left <PastCompanyLink /> and might be Searching a new job opportunity
      </>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.HR &&
    signal_type === TALENT_SIGNAL_TYPE.PROMOTION
  ) {
    return (
      <>
        Got a Promotion at <NewCompanyLink /> as "{current_position_title}"
      </>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.LIVE &&
    signal_type === TALENT_SIGNAL_TYPE.INVESTMENT
  ) {
    return (
      <>
        Made an Investment in <NewCompanyLink />
      </>
    )
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.STEALTH &&
    signal_type === TALENT_SIGNAL_TYPE.INVESTMENT
  ) {
    return <>Made an Investment in a Stealth company</>
  }

  if (
    signal_status === TALENT_SIGNAL_STATUS.OUT_OF_STEALTH &&
    signal_type === TALENT_SIGNAL_TYPE.INVESTMENT
  ) {
    return (
      <>
        Comes Out of Stealth and announces an Investment in <NewCompanyLink />
      </>
    )
  }

  return null
}

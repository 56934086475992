import { Box, Flex, Heading, Link, SimpleGrid, Text } from "@chakra-ui/react"
import { allTalentSignalStatus } from "~/consts/talentSignals"
import { isOneOf } from "~/utils/isOneOf"
import { getSignalDate } from "~/utils/signalDate"

import {
  BoxIcon,
  CompanyLogo,
  FlexDivider,
  SignalStatusTalent,
  TalentSignalTitle,
} from "~/components"
import { Card } from "~/components/Card"
import { isDateValid } from "~/utils/isDateValid"
import { CardBox } from "../CardBox"
import { ColoredTag } from "../ColoredTag"
import { GetPeopleByID } from "~/utils/db/getPeopleByID"
import { HiBriefcase } from "react-icons/hi"
import { Link as RemixLink } from "@remix-run/react"
import { ensureAbsoluteLink, stripProtocol } from "~/utils/string/url"

export const TalentSignalHeadlineCard = ({
  signal,
}: {
  signal: GetPeopleByID
}) => {
  if (!("signal_type" in signal)) return null

  return (
    <CardBox>
      <Card borderWidth={1} borderColor="brand.300">
        <Flex alignItems="start">
          <Box flex={1}>
            <Flex gap={1} flexWrap="wrap">
              {signal.signal_type && (
                <ColoredTag color="gray" rounded="full">
                  {signal.signal_type}
                </ColoredTag>
              )}

              {signal.signal_score && (
                <ColoredTag rounded="full" color="gray">
                  Score: {signal.signal_score}/10
                </ColoredTag>
              )}
            </Flex>

            <Heading as="h1" fontSize="md" my={2}>
              <TalentSignalTitle talentSignal={signal} />
            </Heading>

            {/* <TalentSignalNewCompanyBox talentSignal={signal} /> */}

            {isDateValid(signal.signal_date) && (
              <Text fontSize="xs" color="gray.500" fontWeight="semibold">
                {getSignalDate(new Date(signal.signal_date))}
              </Text>
            )}
          </Box>

          <Flex alignItems="center" gap={2}>
            {isOneOf(signal.signal_status, allTalentSignalStatus) && (
              <SignalStatusTalent signalStatus={signal.signal_status} />
            )}

            {signal.out_of_stealth_advantage !== null && (
              <Text color="gray.300" fontSize="xs" fontWeight="semibold">
                {signal.out_of_stealth_advantage} ADV
              </Text>
            )}
          </Flex>
        </Flex>
      </Card>
    </CardBox>
  )
}

// Not needed right now, but may be in the future
const _TalentSignalNewCompanyBox = ({
  talentSignal,
}: {
  talentSignal: GetPeopleByID
}) => {
  if (!("signal_status" in talentSignal)) return null

  const {
    current_position_company_website,
    current_position_company_name,
    current_position_specter_company_id,
    current_position_company_tagline,
    current_position_company_tags,
  } = talentSignal

  const current_position_company_domain = stripProtocol(
    current_position_company_website ?? ""
  )

  return (
    <Card
      p={3}
      my={3}
      w="fit-content"
      minW="200px"
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <SimpleGrid templateColumns="auto 1fr" gap={2}>
        {current_position_company_domain ? (
          <CompanyLogo domain={current_position_company_domain} size={10} />
        ) : (
          <BoxIcon icon={HiBriefcase} size={8} />
        )}
        <Flex
          direction="column"
          justifyContent="center"
          fontSize="xs"
          overflow="hidden"
          flexShrink={1}
        >
          {current_position_specter_company_id ? (
            <Link
              color="brand.500"
              as={RemixLink}
              to={`/signals/company/feed/${current_position_specter_company_id}/f`}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              fontSize="sm"
            >
              {current_position_company_name}
            </Link>
          ) : (
            <Text
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              fontSize="sm"
            >
              {current_position_company_name}
            </Text>
          )}
          <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {current_position_company_tagline}
          </Text>
        </Flex>
      </SimpleGrid>
      <FlexDivider orientation="horizontal" />
      <Flex gap={2} alignItems="center">
        <Text fontSize="xs" color="gray.500" fontWeight="semibold">
          Website:{" "}
        </Text>
        <Link
          href={ensureAbsoluteLink(current_position_company_website)}
          target="_blank"
          color="brand.500"
          fontSize="xs"
        >
          {stripProtocol(current_position_company_website ?? "")}
        </Link>
      </Flex>
      <Flex gap={2} alignItems="center">
        <Text fontSize="xs" color="gray.500" fontWeight="semibold">
          Tags:{" "}
        </Text>
        {current_position_company_tags ? (
          current_position_company_tags?.split(",").map((tag) => (
            <ColoredTag key={tag} color="gray">
              {tag}
            </ColoredTag>
          ))
        ) : (
          <Text color="gray.400" fontWeight="semibold" fontSize="xs">
            N/A
          </Text>
        )}
      </Flex>
    </Card>
  )
}

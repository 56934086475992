import {
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useState } from "react"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { ListType } from "~/routes/__protected/user/lists/$product"
import { useHiddenSearchesLists } from "~/utils/hooks/useHiddenSearchesLists"
import { useLocalStorage } from "~/utils/hooks/useLocalStorage"
import { capitalize } from "~/utils/string/format"
import { MenuItem } from "../MenuItem"

type Props =
  | {
      type: ListType.userList
      id: string
    }
  | {
      type: ListType.savedSearch
      id: number
    }

export function HideListOrSearch({ id, type }: Props): JSX.Element {
  const modal = useDisclosure()
  const toast = useToast()

  const { hidden, hide, unhide } = useHiddenSearchesLists(type)

  const isHidden = hidden.includes(id.toString())

  const action = isHidden ? "Unhide" : "Hide"
  const [doNotShowAnymore, setDoNotShowAnymore] = useState(false)

  const [lsDoNotShowAnymore, setLsDoNotShowAnymore] = useLocalStorage(
    `specter-do-not-show-hide-message-${type}`,
    false
  )

  const handleSubmit = () => {
    if (isHidden) unhide(id.toString())
    else hide(id.toString())

    modal.onClose()

    toast({
      status: "success",
      title: `${capitalize(type)} updated successfully`,
    })

    if (doNotShowAnymore) setLsDoNotShowAnymore(true)
  }

  return (
    <>
      <MenuItem
        icon={isHidden ? HiEye : HiEyeOff}
        onClick={lsDoNotShowAnymore ? handleSubmit : modal.onOpen}
      >
        {action}
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            {action} {capitalize(type)}
          </ModalHeader>
          <ModalBody>
            <Text>
              If you don't want this {capitalize(type)} to appear in this page,
              you can hide it. You can unhide it at any time.
            </Text>
            <Flex gap={2} alignItems="center" mt={2}>
              <Checkbox
                id="doNotShowAnymore"
                isChecked={doNotShowAnymore}
                onChange={() => setDoNotShowAnymore((prev) => !prev)}
                name="doNotShowAnymore"
                size="sm"
              />
              <FormLabel htmlFor="doNotShowAnymore" fontSize="xs" m={0}>
                Do not show this message again
              </FormLabel>
            </Flex>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button variant="outline" size="sm" onClick={modal.onClose}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="brand" onClick={handleSubmit}>
              {action}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

import { Flex, Grid, Link, Text } from "@chakra-ui/react"
import { HiBriefcase } from "react-icons/hi"

import { Link as RemixLink } from "@remix-run/react"
import { BoxIcon } from "~/components/BoxIcon"
import { Card } from "~/components/Card"
import { CompanyLogo } from "~/components/CompanyLogo"
import { GetPeopleByID } from "~/utils/db/getPeopleByID"
import { stripProtocol } from "~/utils/string/url"
import { FlexDivider } from "../FlexDivider"

interface Props {
  talentSignal: GetPeopleByID
  limit?: number
}

export const TalentSignalPastPositionsCardSmall = ({
  talentSignal,
}: Props): JSX.Element | null => {
  if (!("signal_type" in talentSignal)) return null
  if (talentSignal.past_position_company_website === null) return null

  const [beforeLastPosition1, beforeLastPosition2] =
    talentSignal.experience
      ?.filter((exp) => !exp.is_current)
      ?.filter(
        (exp) =>
          exp.company_name !== talentSignal.past_position_company_name &&
          stripProtocol(exp.domain ?? "") !==
            stripProtocol(talentSignal.past_position_company_website ?? "")
      )
      .slice(0, 2) ?? []

  if (!talentSignal.past_position_company_name) return null

  return (
    <Card p={4} display="flex" flexDirection="column" gap={2} maxW="500px">
      <Text as="h2" color="gray.800" fontSize="sm" fontWeight="bold">
        Past Positions
      </Text>

      <Grid
        gap={2}
        templateColumns={
          beforeLastPosition1 || beforeLastPosition2
            ? "auto auto auto auto"
            : "auto 1fr"
        }
        justifyContent="start"
      >
        {talentSignal.past_position_company_website ? (
          <CompanyLogo
            domain={talentSignal.past_position_company_website}
            size={8}
          />
        ) : (
          <BoxIcon icon={HiBriefcase} size={8} />
        )}

        <Flex
          direction="column"
          justifyContent="center"
          fontSize="xs"
          overflow="hidden"
          flexShrink={1}
        >
          {talentSignal.past_position_company_website ? (
            <Link
              color="brand.500"
              {...(talentSignal.past_position_specter_company_id
                ? {
                    as: RemixLink,
                    to: `/signals/company/feed/${talentSignal.past_position_specter_company_id}/f`,
                  }
                : {
                    href:
                      talentSignal.past_position_company_website ?? undefined,
                    target: "_blank",
                    to: "",
                  })}
              onClick={(e) => e.stopPropagation()}
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {talentSignal.past_position_company_name}
            </Link>
          ) : (
            <Text
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {talentSignal.past_position_company_name}
            </Text>
          )}
          <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {talentSignal.past_position_title}
          </Text>
        </Flex>
        {(beforeLastPosition1 || beforeLastPosition2) && (
          <>
            <FlexDivider orientation="vertical" />
            <Flex
              direction="column"
              justifyContent="center"
              fontSize="xs"
              overflow="hidden"
              flexShrink={5}
            >
              {beforeLastPosition1 && (
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  <Text as="span" fontWeight="semibold">
                    {beforeLastPosition1.company_name}
                  </Text>
                  {" - "}
                  {beforeLastPosition1.title}
                </Text>
              )}
              {beforeLastPosition2 && (
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  <Text as="span" fontWeight="semibold">
                    {beforeLastPosition2.company_name}
                  </Text>
                  {" - "}
                  {beforeLastPosition2.title}
                </Text>
              )}
            </Flex>
          </>
        )}
      </Grid>
    </Card>
  )
}
